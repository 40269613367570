import React, { createContext, useState, ReactNode } from 'react';
import axios from "axios";
import { useNavigate} from "react-router-dom";

type User = {
    firstName: string;
    lastName : string;
    email : string;
    phone: string;
    auth: boolean;
    token : string;
};

// Create the context with an initial value of null
const UserContext = createContext<{
    user: User | null;
    login: (firstName: string,
            lastName: string,
            email: string,
            phone: string,
            auth : boolean,
            token : string) => void;
    logout: () => void;
    verifyUser: () => void;
} | null>(null);

type UserProviderProps = {
    children: ReactNode;
};

const UserProvider = ({ children }: UserProviderProps) => {
    const [user, setUser] = useState<User | null>(null);
    const login = (
                    firstName: string,
                   lastName: string,
                   email: string,
                   phone: string,
                   auth : boolean,
                   token : string
    ) => {
        setUser({
            firstName: firstName,
            lastName : lastName,
            email : email,
            phone: phone,
            auth: auth,
            token : token
        });
    };

    const logout = () => {
        setUser(null);
        localStorage.setItem("token","");
    };


    const verifyUser = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            // Possibly reset user context or do nothing if no token is found
            return;
        }
        try {
            const response = await axios.post('http://bolt2024.catchavibes.com/api/v1/public/validateToken', localStorage.getItem("token"));

            if (response.status === 200) {


                login(
                    response.data.firstName,
                    response.data.lastName,
                    response.data.email,
                    response.data.phone,
                    true,
                    response.data.token);
            }
            else {
                logout()
            }
        } catch (error) {
            console.error("User verification failed", error);
            logout()
        }
    };

    return (
        <UserContext.Provider value={{ user, login, logout, verifyUser  }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };
