import React from 'react';
import { Container, Typography, Grid, Paper } from '@mui/material';
import ShuttleIcon from '@mui/icons-material/DirectionsBus';
import AirportIcon from '@mui/icons-material/LocalAirport';
import CruiseShipIcon from '@mui/icons-material/DirectionsBoat';
import HotelIcon from '@mui/icons-material/Hotel';
import CashIcon from '@mui/icons-material/Money';
import FriendlyIcon from '@mui/icons-material/EmojiPeople';
import ScenicRoutesIcon from '@mui/icons-material/Explore';
import LocationIcon from '@mui/icons-material/LocationOn';

const ServicesOffered = () => {
    const services = [
        { title: 'Shuttle From Hotel to Tour Meetup', icon: <ShuttleIcon /> },
        { title: 'Airport Transfers', icon: <AirportIcon /> },
        { title: 'Cruise Ship Pick up', icon: <CruiseShipIcon /> },
        { title: 'Hotel Transfers', icon: <HotelIcon /> },
        { title: 'Onsite Cash Payments', icon: <CashIcon /> },
        { title: 'Friendly Chaperone', icon: <FriendlyIcon /> },
        { title: 'Scenic Routes', icon: <ScenicRoutesIcon /> },
        { title: 'Location Narration', icon: <LocationIcon /> },
    ];

    return (
        <Container>

            <Grid container spacing={3}>
                {services.map((service, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
                            {service.icon}
                            <Typography variant="subtitle1" style={{ marginTop: '10px' }}>
                                {service.title}
                            </Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default ServicesOffered;
