import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCart } from "../Auth/CartContext";

const BookingStep = () => {
    const { bookingInformation } = useCart();

    // Local state to hold the values for immediate updates
    const [localBookingInfo, setLocalBookingInfo] = useState(bookingInformation);

    // Update local state when context changes
    useEffect(() => {
        setLocalBookingInfo(bookingInformation);
    }, [bookingInformation]);

    const handleChange = (field,  value) => {
        // Update both local state and context
        setLocalBookingInfo(prevState => ({
            ...prevState,
            [field]: value,
        }));
        bookingInformation[field] = value;
    };

    return (
        <Stack spacing={2}>
            <Typography variant="h5" align="center" color="primary">
                Personal Information
            </Typography>

            {/* First Name */}
            <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                required
                inputProps={{ style: { borderRadius: 8 } }}
                onChange={(e) => handleChange('firstName', e.target.value)}
                value={localBookingInfo.firstName || ''}
            />

            {/* Last Name */}
            <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                required
                inputProps={{ style: { borderRadius: 8 } }}
                onChange={(e) => handleChange('lastName', e.target.value)}
                value={localBookingInfo.lastName || ''}
            />

            {/* Phone Number */}
            <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                required
                inputProps={{ style: { borderRadius: 8 } }}
                onChange={(e) => handleChange('phone', e.target.value)}
                value={localBookingInfo.phone || ''}
            />

            {/* Email */}
            <TextField
                label="Email"
                type="email"
                variant="outlined"
                fullWidth
                required
                inputProps={{ style: { borderRadius: 8 } }}
                onChange={(e) => handleChange('email', e.target.value)}
                value={localBookingInfo.email || ''}
            />

            <div>Number of Passengers on Booking</div>

            {/* Number of Children */}
            <TextField
                label="Number of Children Under 14"
                type="number"
                variant="outlined"
                fullWidth
                inputProps={{
                    style: { borderRadius: 8 },
                    min: 0,
                    max: 6,
                }}
                required
                value={localBookingInfo.numberOfChildren || ''}
                onChange={(e) => handleChange('numberOfChildren', e.target.value)}
            />

            {/* Number of Adults */}
            <TextField
                label="Number of Adults"
                type="number"
                variant="outlined"
                fullWidth
                required
                inputProps={{
                    style: { borderRadius: 8 },
                    min: 0,
                    max: 6,
                }}
                value={localBookingInfo.numberOfAdults || ''}
                onChange={(e) => handleChange('numberOfAdults', e.target.value)}
            />


            <Stack direction="row" spacing={2}>
                <TextField
                    label={""}
                    type={"date"}
                    variant={"outlined"}
                    fullWidth
                    required
                    inputProps={{
                        style: { borderRadius: 8 }
                    }}
                    value={localBookingInfo.date || ''}
                    onChange={(e) => handleChange('date', e.target.value)}

                />
                <TextField
                    label={""}
                    type={"time"}
                    variant={"outlined"}
                    fullWidth
                    required
                    inputProps={{
                        style: { borderRadius: 8 }
                    }}
                    value={localBookingInfo.time || ''}
                    onChange={(e) => handleChange('time', e.target.value)}

                />
            </Stack>
        </Stack>
    );
};

export default BookingStep;
