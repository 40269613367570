const visitedPlacesData = [
    {
        id:0,
        imgUrl: "/images/general/bobmarleymuseum.jpg",
        locationName: "Bob Marley Museum",
        locationText: "Enjoy the sun and sea at our beautiful beach paradise.",
        availableTour:["Kingston"]

    },
    {
        id:1,
        imgUrl: "/images/general/bluelagoon.jpg",
        locationName: "Blue Lagoon",
        locationText: "Experience the tranquility of the mountains in our retreat.",
        availableTour:["Portland"]
    },
    {
        id:2,
        imgUrl: "/images/general/bostonjerkcenter.jpg",
        locationName: "Boston Jerk Center",
        locationText: "Enjoy the sun and sea at our beautiful beach paradise.",
        availableTour:["Portland"]
    },
    {
        id:3,
        imgUrl: "/images/general/devonhouse.jpg",
        locationName: "Devon House",
        locationText: "Experience the tranquility of the mountains in our retreat.",
        availableTour:["Kingston"]
    },
    {
        id:4,
        imgUrl: "/images/general/portroyal.jpg",
        locationName: "Port Royal",
        locationText: "Enjoy the sun and sea at our beautiful beach paradise.",
        availableTour:["Kingston"]
    },
    {
        id:5,
        imgUrl: "/images/general/puertoseco.jpg",
        locationName: "Puerto Seco",
        locationText: "Experience the tranquility of the mountains in our retreat.",
        availableTour:["Ocho Rios", "Montego Bay"]
    },
    {
        id:6,
        imgUrl: "/images/general/hopegarden.jpg",
        locationName: "Hope Garden",
        locationText: "Enjoy the sun and sea at our beautiful beach paradise.",
        availableTour:["Kingston"]
    },
    {
        id:7,
        imgUrl: "/images/general/dunnsriver.jpg",
        locationName: "Dunns River",
        locationText: "Experience the tranquility of the mountains in our retreat.",
        availableTour:["Ocho Rios", "Montego Bay"]
    },
    // Add more objects with actual data as needed
];

export default visitedPlacesData;
