import React from 'react';
import { Container, Grid, Link, Paper, Typography, TextField, Button, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';

const Footer = () => {
    return (
        <footer id="main-footer" style={{ marginTop: '0px', backgroundColor: '#262626', padding: '30px 0' }}>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>
                        <Link href="index.html">
                            <img src="images/logo/logobg.png" alt="Logo Image" width="50%" />
                        </Link>
                        <Typography variant="body2" paragraph style={{ color: '#fff' }}>
                            Booking, reviews and advice on hotels, resorts, flights, vacation rentals, travel packages, and more!
                        </Typography>
                        <div style={{color:'#04ADEC'}}>
                            <IconButton href="https://www.facebook.com/profile.php?id=100091668991114&mibextid=LQQJ4d" style={{color:'#04ADEC'}}>
                                <FacebookIcon />
                            </IconButton>
                            <IconButton href="https://twitter.com/catchavibesbolt?s=21" style={{color:'#04ADEC'}}>
                                <TwitterIcon />
                            </IconButton>
                            <IconButton href="#" style={{color:'#04ADEC'}}>
                                <InstagramIcon />
                            </IconButton>
                            <IconButton href="#" style={{color:'#04ADEC'}}>
                                <PinterestIcon />
                            </IconButton>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" gutterBottom style={{ color: '#fff' }}>
                            Newsletter
                        </Typography>
                        <form>
                            <TextField
                                label="Enter your E-mail Address"
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                InputProps={{ style: { background: '#fff' } }}
                            />
                            <Typography variant="body2" paragraph style={{ color: '#fff' }}>
                                <small>*We Never Send Spam</small>
                            </Typography>
                            <Button variant="contained" color="primary" fullWidth style={{ backgroundColor: '#450272', color: '#fff' }}>
                                Subscribe
                            </Button>
                        </form>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="h6" gutterBottom style={{ color: '#fff' }}>
                            Links
                        </Typography>
                        <ul style={{ listStyle: 'none', padding: 0, color:'#04ADEC'}}>
                            <li>
                                <Link href="/faqs" color="inherit" underline="hover">
                                    FAQs
                                </Link>
                            </li>
                            <li>
                                <Link href="/" color="inherit" underline="hover">
                                    Jobs
                                </Link>
                            </li>
                            <li>
                                <Link href="/terms" color="inherit" underline="hover">
                                    Privacy Policy
                                </Link>
                            </li>
                            <li>
                                <Link href="/terms" color="inherit" underline="hover">
                                    Terms of Use
                                </Link>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom style={{ color: '#fff' }}>
                            Have Questions?
                        </Typography>
                        <Typography variant="body1" color="textSecondary" paragraph style={{ color: '#fff' }}>
                            Local:
                            <br />
                            876-384-7033
                        </Typography>
                        <Typography variant="body1" color="textSecondary" paragraph style={{ color: '#fff' }}>
                            International:
                            <br />
                            +1-585-358-9545
                        </Typography>
                        <Typography variant="body1" paragraph>
                            <Link href="#" color="#04ADEC">
                                bolttourscs@gmail.com
                            </Link>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" style={{ color: '#fff' }}>
                            Dedicated Customer Support
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
};

export default Footer;
