// Import necessary types from React
import React, { createContext, useContext, useState } from 'react';

type Destination = {
    destinationID: any;
    destinationImgUrl: any;
    destinationName: any;
    destinationPrice : any
};

type CartContextType = {
    selectedDestinations: Destination[];
    paymentInformation: {
        cardNumber: string;
        cardCVC: string;
        cardExpDate: string;
        cardHolderName: string;
    };
    bookingInformation: {
        firstName: string;
        lastName: string;
        phone: string;
        email: string;
        numberOfChildren: number;
        numberOfAdults: number;
        subtotal:number;
        tax:number;
        total: number;
        date:Date;
        time;
    };
    addToCart: (destinationId: any, destinationPrice: any, destinationImgUrl: any, destinationName: any) => void;
    removeFromCart: (destinationId: any) => void;
    clearCart: () => void;
};

const CartContext = createContext<CartContextType | null>(null);

export const useCart = () => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};

export const CartProvider = ({ children }) => {
    const [selectedDestinations, setSelectedDestinations] = useState<Destination[]>([]);

    const [paymentInformation, setPaymentInformation] = useState({
        cardNumber: '',
        cardCVC: '',
        cardExpDate: '',
        cardHolderName: '',
    });

    const [bookingInformation, setBookingInformation] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        numberOfChildren: 0,
        numberOfAdults: 0,
        subtotal:0,
        tax:0,
        total: 0,
        date:null,
        time: null
    });


    const addToCart = (destinationId: any, destinationPrice: any, destinationImgUrl: any, destinationName : any) => {
        // Check if the destination is already in the cart
        const existingIndex = selectedDestinations.findIndex(
            (destination) => destination.destinationID === destinationId
        );

        if (existingIndex === -1) {
            setSelectedDestinations((prevDestinations) => [
                ...prevDestinations,
                {
                    destinationID: destinationId,
                    destinationPrice: destinationPrice,
                    destinationImgUrl: destinationImgUrl,
                    destinationName: destinationName, // You can replace this with the actual name
                },
            ]);
        }
    };

    const removeFromCart = (destinationId: any) => {
        const updatedDestinations = selectedDestinations.filter(
            (destination) => destination.destinationID !== destinationId
        );

        setSelectedDestinations(updatedDestinations);
    };

    const clearCart = () => {
        setSelectedDestinations([]);
    };

    const contextValue: CartContextType = {
        selectedDestinations,
        paymentInformation,
        bookingInformation,
        addToCart,
        removeFromCart,
        clearCart,
    };

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    );
};

export default CartProvider;
