import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { useCart } from '../Auth/CartContext';
import { useNavigate } from 'react-router-dom';
import {Chip} from "@mui/material";

export default function VisitedPlacesCardHome(props) {
    const { selectedDestinations } = useCart();
    return (
        <Card  sx={{ display: 'flex', width: '100%', height: '300px', borderRadius: '8px', boxShadow: 8 }}>
            <CardMedia
                component="img"
                sx={{ width: '50%', objectFit: 'cover', borderRadius: '8px 0 0 8px' }}
                image={props.imgUrl}
                alt={props.imgUrl}
            />

            <CardContent sx={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                <div style={{ fontSize: "30px" }}>
                    {props.locationName}
                </div>
                <Typography variant="body2">{props.locationText}</Typography>

                <br/>
                <Typography variant="body2">Available Tours:</Typography>
                <br/>
                {props.availableTour.map((tour, index) => (
                    <>
                        <Chip key={index} label={tour}  />
                        <br/>
                    </>

                ))}

            </CardContent>
        </Card>
    );
}
