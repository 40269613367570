import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Grid, Divider } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar';
import { UserContext } from "../Auth/UserProvider";
import Trips from "../Components/Trips";
import axios from "axios";

const theme = createTheme();

const Dashboards = () => {
    const { user, verifyUser } = useContext(UserContext);

    const [userInfo, setUserInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        points: 120,
    });

    const [bookings, setBookings] = useState([]);

    const [trips, setTrips] = useState({
        upcoming: [],
        past: []
    });

    useEffect(() => {
        let shouldVerifyUser = Object.values(userInfo).some(value => value === "" || value === null);

        if (shouldVerifyUser) {
            verifyUser();
        }

        if (user) {
            setUserInfo(prevState => ({
                ...prevState,
                firstName: user.firstName || "",
                lastName: user.lastName || "",
                email: user.email || "",
                phone: user.phone || "",
                points: 120,
            }));
        }
    }, [user, verifyUser]);

    useEffect(() => {
        const loadBookings = async () => {
            if (user) {
                try {
                    const response = await axios.get( "http://bolt2024.catchavibes.com/api/v1/getBookings", {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        }
                    });
                    setBookings(response.data);
                } catch (error) {
                    console.error('Failed to fetch bookings:', error);
                    // Handle error appropriately
                }
            }
        };

        loadBookings();
    }, [user]);

    useEffect(() => {
        if (bookings.length) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const pastTrips = bookings.filter(booking => new Date(booking.date) < today);
            const upcomingTrips = bookings.filter(booking => new Date(booking.date) >= today);

            setTrips({
                upcoming: upcomingTrips,
                past: pastTrips
            });
        }
    }, [bookings]);


    return (
        <ThemeProvider theme={theme}>
            <Navbar />
            <Container sx={{ py: 4, backgroundColor: '#f0f2f5' }}>
                <Typography variant="h4" sx={{ my: 5, textAlign: 'center', color: '#333', fontWeight: 'bold' }}>
                    Welcome to Your Dashboard, {userInfo.firstName}
                </Typography>
                <Divider sx={{ my: 4 }} />
                <Grid container spacing={4}>
                    {trips.upcoming.length > 0 ? <Trips trips={trips.upcoming} title={'Upcoming Trips'} /> :
                        <Typography variant={"body1"}>No upcoming trips</Typography>
                    }

                    {trips.past.length > 0 ? <Trips trips={trips.past} title={'Past Trips'} /> :
                        <Typography variant={"body1"}>No past trips</Typography>
                    }
                </Grid>
            </Container>
            <Footer />
        </ThemeProvider>
    );
};

export default Dashboards;