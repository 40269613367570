import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const NoPage = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <Typography variant="h3" color="primary" gutterBottom>
                404 - Page Not Found
            </Typography>
            <Typography variant="h5" gutterBottom>
                Oops! Looks like you've reached a page that doesn't exist.
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
                The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
            </Typography>
            <Button variant="contained" color="primary" component={Link} to="/">
                Go to Home
            </Button>
        </div>
    );
};

export default NoPage;
