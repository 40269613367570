import React, {useEffect} from 'react';
import Typography from '@mui/material/Typography';
import { useCart } from '../Auth/CartContext';
import { Grid } from "@mui/material";

interface Destination {
    destinationID: number;
    destinationImgUrl: string;
    destinationName: string;
    destinationPrice: number;
}

interface BookingInformation {
    firstName: string;
    lastName: string;
    email: string;
    numberOfAdults: number;
    numberOfChildren: number;
}

interface CouponDetails {
    code: string;
    discountPercentage: number;
    appliesTo: "entireCost" | "adultTicket" | "childrenTicket" | "none";
    numberOfTickets: number;
}

const ReviewStep: React.FC = () => {
    const { selectedDestinations, bookingInformation } = useCart();
    const { firstName, lastName, email, numberOfAdults, numberOfChildren } = bookingInformation || {};

    // Mock coupon details for now
    const couponDetails: CouponDetails = {
        code: "SUMMER50",
        discountPercentage: 0,
        appliesTo: "entireCost", // or "adultTicket" or "childrenTicket" or "none"
        numberOfTickets: 0
    };

    const isChildrenDiscountActive: boolean = true; // Set to false to disable children discount

    const destinationCostTotal: number = selectedDestinations.reduce((acc: number, destination: Destination) => acc + destination.destinationPrice, 0);

    let childrenCost: number = 0; // Declare childrenCost outside the function

    const applyCouponDiscount = (amount: number, discountPercentage: number): number => {
        return amount * ((100 - discountPercentage) / 100);
    };

    const calculateTotalCost = (): number => {
        const adultsCost: number = numberOfAdults * destinationCostTotal;
        childrenCost = isChildrenDiscountActive ? numberOfChildren * (destinationCostTotal / 2) : numberOfChildren * destinationCostTotal;

        let totalCost: number = adultsCost + childrenCost;

        if (couponDetails.code) {
            switch (couponDetails.appliesTo) {
                case "entireCost":
                    totalCost = applyCouponDiscount(totalCost, couponDetails.discountPercentage);
                    break;
                case "adultTicket":
                    totalCost = adultsCost + applyCouponDiscount(childrenCost, couponDetails.discountPercentage);
                    break;
                case "childrenTicket":
                    totalCost = applyCouponDiscount(adultsCost, couponDetails.discountPercentage) + childrenCost;
                    break;
                default:
                    break;
            }
        }

        return totalCost; // Ensure total is not less than subtotal
    };

    const totalCost: number = calculateTotalCost();
    const taxRate: number = 0; // 6.25%
    const tax: number = totalCost * taxRate;
    const totalAfterTax: number = totalCost + tax;
    const adultsCostAfterCoupon: number = applyCouponDiscount(numberOfAdults * destinationCostTotal, couponDetails.discountPercentage);
    const childrenCostAfterCoupon: number = applyCouponDiscount(isChildrenDiscountActive ? numberOfChildren * (destinationCostTotal / 2) : numberOfChildren * destinationCostTotal, couponDetails.discountPercentage);
    const discountOnChildren: number = isChildrenDiscountActive
        ? applyCouponDiscount(childrenCost, couponDetails.discountPercentage) - childrenCostAfterCoupon
        : 0;
    const subtotal = (adultsCostAfterCoupon + childrenCostAfterCoupon).toFixed(2);

    useEffect(()=>{
        bookingInformation.tax = tax;
        bookingInformation.subtotal = Number(subtotal);
        bookingInformation.total = totalAfterTax;
    },[])

    return (
        <div>
            <Typography variant="h3" align="center" color="primary">
                Review and Finalize Booking
            </Typography>

            {/* Display Selected Destinations */}
            <Typography variant="h6" color="primary" gutterBottom>
                Selected Destinations:
            </Typography>
            <br />
            <Grid container spacing={2}>
                {selectedDestinations.map((destination: Destination) => (
                    <Grid item key={destination.destinationID}>
                        <div style={{ textAlign: 'center' }}>
                            <img src={destination.destinationImgUrl} alt={destination.destinationName} style={{ width: '80px', height: '80px', borderRadius: '50%', objectFit: 'cover' }} />
                            <Typography variant="subtitle1" color="primary" style={{ marginTop: '8px' }}>
                                {destination.destinationName}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                $ {destination.destinationPrice}
                            </Typography>
                        </div>
                    </Grid>
                ))}
            </Grid>
            <br />

            {/* Display Booking Information */}
            <Typography variant="h6" color="primary" gutterBottom>
                Booking Information:
            </Typography>
            <Typography>Name: {firstName} {lastName}</Typography>
            <Typography>Email: {email}</Typography>

            {/* Display Order Details */}
            <Typography variant="h6" color="primary" gutterBottom>
                Order Details:
            </Typography>
            <Typography>Subtotal: ${(numberOfAdults * destinationCostTotal + (isChildrenDiscountActive ? numberOfChildren * (destinationCostTotal / 2) : numberOfChildren * destinationCostTotal)).toFixed(2)}</Typography>
            <Typography>Tax: $0 - Included in price</Typography>
            <Typography>Total: ${totalAfterTax.toFixed(2)}</Typography>

            {/* Display Cost Breakdown */}
            <Typography variant="h6" color="primary" gutterBottom>
                Cost Breakdown:
            </Typography>
            <Typography>Adults ({numberOfAdults}): ${adultsCostAfterCoupon.toFixed(2)}</Typography>
            <Typography>Children ({numberOfChildren}) : ${childrenCostAfterCoupon.toFixed(2)}
                <br />
                {couponDetails.appliesTo === "childrenTicket" && `${couponDetails.discountPercentage}% Discount on Children: -${discountOnChildren.toFixed(2)}`}
            </Typography>
            <Typography>Subtotal: ${subtotal}</Typography>
            <Typography>Tax: ${tax.toFixed(2)}</Typography>
            <Typography>Total After Tax: ${totalAfterTax.toFixed(2)}</Typography>
        </div>
    );
};

export default ReviewStep;
