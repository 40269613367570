import React from 'react';
import { Card, CardContent, Chip, Divider, Grid, Typography, CardMedia } from '@mui/material';
import { format } from 'date-fns';
import Avatar from '@mui/material/Avatar';

const colors = [
    { bg: '#8A2BE2', text: '#FFFFFF' }, // Purple
    { bg: '#E69F08', text: '#000000' }, // Orange
    { bg: '#34A853', text: '#FFFFFF' }, // Green
    { bg: '#4169E1', text: '#FFFFFF' }  // Blue
];

const Trips = (props) => {

    const assignColor = (index) => colors[index % colors.length];

    return (
        <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{ margin: 5, color: '#333', fontWeight: 'bold', textAlign: 'center' }}>
                {props.title}
            </Typography>

            {props.trips && props.trips.map((trip, index) => (
                <Card
                    key={trip.id}
                    sx={{
                        marginBottom: 4,
                        borderRadius: '15px',
                        overflow: 'hidden',
                        boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                        transition: 'transform 0.15s ease-in-out',
                        '&:hover': {
                            transform: 'translateY(-5px)',
                            boxShadow: '0 10px 30px rgba(0,0,0,0.18)',
                        },
                    }}
                >
                    <CardMedia
                        component="img"
                        height="140"
                        image={trip.destination[0].imgUrl || "/path/to/default/image.jpg"} // Fallback to a default image
                        alt="Destination image"
                    />

                    <CardContent>
                        <Typography variant="subtitle1" gutterBottom>
                            { format(new Date(trip.date), 'MMMM dd, yyyy')}
                        </Typography>
                        {trip.destination.map((destination, index) => (
                            <Chip
                                key={destination.id}
                                label={destination.destinationName}
                                variant="filled"
                                size="small"
                                sx={{
                                    marginRight: "5px",
                                    backgroundColor: assignColor(index).bg,
                                    color: assignColor(index).text,
                                }}
                            />
                        ))}
                        <Typography variant="body2" color="textSecondary">
                            Adults: {trip.numberOfAdults}  | Children: {trip.numberOfChildren}
                        </Typography>
                        <Typography variant="body2">
                            Status: {trip.payment.paymentStatus}
                        </Typography>
                        <Typography variant="body2">
                            Record Locator: {trip.recordLocator}
                        </Typography>
                        <Divider sx={{ margin: "5px" }} />
                        <Typography variant="body2" sx={{ textAlign: "right" }}>
                            <b>Your Driver :</b>
                            <br />
                            <Chip
                                avatar={<Avatar alt="Unassigned" src="/static/images/avatar/1.jpg" />}
                                label="Unassigned"
                                variant="outlined"
                            />
                        </Typography>
                    </CardContent>
                </Card>
            ))}
        </Grid>
    );
};

export default Trips;
