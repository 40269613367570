import React from 'react';
import Slider from "./Slider";

const HeroSlider = () => {
    const slides = [
        {
            id: 1,
            url:
                "https://images.unsplash.com/photo-1488646953014-85cb44e25828?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1935&q=80",
            mobileUrl:
                "https://images.unsplash.com/photo-1488646953014-85cb44e25828?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1935&q=80",
            title: "Welcome!",
            subtitle: "Packages Starting at $180 USD",
            buttonLink:
                "https://medium.com/@antonvaits/create-a-responsive-image-slider-with-smooth-transitions-using-react-and-css-44c95bbff30a",
            buttonText: "Book Now"
        },
        {
            id: 2,
            url:
                "https://images.unsplash.com/photo-1476514525535-07fb3b4ae5f1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
            mobileUrl:
                "https://images.unsplash.com/photo-1476514525535-07fb3b4ae5f1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
            title: "Welcome!",
            subtitle: "50 % off for Children",
            buttonLink:
                "https://medium.com/@antonvaits/create-a-responsive-image-slider-with-smooth-transitions-using-react-and-css-44c95bbff30a",
            buttonText: "Book Now"
        },
        {
            id: 3,
            url:
                "https://images.unsplash.com/photo-1488085061387-422e29b40080?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1931&q=80",
            mobileUrl:
                "https://images.unsplash.com/photo-1488085061387-422e29b40080?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1931&q=80",
            title: "Welcome!",
            subtitle: "Get 20% off with the XMAS2023 promo code ",
            buttonLink:
                "https://medium.com/@antonvaits/create-a-responsive-image-slider-with-smooth-transitions-using-react-and-css-44c95bbff30a",
            buttonText: "Book Now"
        },
        {
            id: 4,
            url:
                "https://images.unsplash.com/photo-1490730141103-6cac27aaab94?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
            mobileUrl:
                "https://images.unsplash.com/photo-1490730141103-6cac27aaab94?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
            title: "Welcome!",
            subtitle: "Accomodation Available",
            buttonLink:
                "https://medium.com/@antonvaits/create-a-responsive-image-slider-with-smooth-transitions-using-react-and-css-44c95bbff30a",
            buttonText: "Book Now"
        }
    ];

    return <Slider slides={slides} />

};

export default HeroSlider;