import React from "react";
import Slider from "react-slick";
import { Grid } from "@mui/material";
import VisitedPlacesCardHome from "./VisitedPlacesCardHome"; // Ensure this is correctly imported
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VisitedPlacesData from "../TestData/VisitedPlacesData";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


const VisitedPlacesSlider = () => {
    // Slider settings
    const settings = {
        dots: true, // Show dot indicators
        infinite: true, // Infinite loop sliding
        speed: 500, // Transition speed
        slidesToShow: 2, // Number of slides to show at a time
        slidesToScroll: 2, // Number of slides to scroll at a time
        rows: 2,
        nextArrow: <ArrowForwardIosIcon sx={{color:"black", '&:hover': {
                color: 'blue', // Color on hover
            } }}/>, // Custom next arrow
        prevArrow: <ArrowBackIosIcon sx={{color:"black", '&:hover': {
                color: 'blue'
            }}}/>,
        responsive: [
            {
                breakpoint: 1024, // Adjusts when the window width is <= 1024px
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600, // Adjusts when the window width is <= 600px
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480, // Adjusts when the window width is <= 480px
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    return (
        <Slider {...settings} style={{backgroundColor:"transparent"}}>
            {VisitedPlacesData.map((data, index) => (
                <div key={index}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12}>
                            <VisitedPlacesCardHome
                                id={data.id}
                                imgUrl={data.imgUrl}
                                locationName={data.locationName}
                                locationText={data.locationText}
                                addIcon={false}
                                availableTour={data.availableTour}
                            />
                        </Grid>
                    </Grid>
                </div>
            ))}
        </Slider>
    );
};

export default VisitedPlacesSlider;
