import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import RoomIcon from '@mui/icons-material/Room';
import FaxIcon from '@mui/icons-material/Fax';
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Container from "@mui/material/Container";

const Contact = () => {
    return (
        <>
            <Navbar/>
            <Container>
                <Typography variant="h2" align="center" gutterBottom sx={{marginTop:'80px',marginBottom:'80px'}}>
                    Contact Us
                </Typography>

                <Grid container spacing={3} justifyContent="center" sx={{marginBottom:'80px'}}>
                    {/* Call Us Section */}
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <PhoneIcon  sx={{fontSize:'150px'}}/>
                        <Typography variant="h6">Call Us</Typography>
                        <Typography>Local: 876-384-7033</Typography>
                        <Typography>Montego Bay Office: 876-342-6736</Typography>
                        <Typography>International: +1-585-358-9545</Typography>
                    </Grid>

                    {/* Fax Section */}
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FaxIcon fontSize="large"  sx={{fontSize:'150px'}}/>
                        <Typography variant="h6">FAX</Typography>
                        <Typography>Unavailable</Typography>
                    </Grid>

                    {/* Office Locations Section */}
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <RoomIcon fontSize="large" sx={{fontSize:'150px'}} />
                        <Typography variant="h6">Office Locations</Typography>
                        <Typography>Montego Bay</Typography>
                        <Typography>Ocho Rios</Typography>
                    </Grid>

                    {/* Email Section */}
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <EmailIcon fontSize="large" sx={{fontSize:'150px'}} />
                        <Typography variant="h6">EMAIL</Typography>
                        <Typography>bolttourscs@gmail.com</Typography>
                    </Grid>
                </Grid>
            </Container>

            <Footer/>
        </>
    );
};

export default Contact;
