import React from "react";
import { Typography, Container } from '@mui/material';
import Navbar from '../Components/Navbar';
import HeroSlider from "../Components/HeroSlider";
import VisitedPlacesSlider from "../Components/VisitedPlacesSlider";
import ServicesOffered from "../Components/ServicesOffered";
import Footer from "../Components/Footer";

const Home = () => {
    return (
        <div>
            <Navbar />
            <HeroSlider />
            {/* Introduction Section */}
            <Container
                maxWidth={'xl'}
                sx={{
                    padding: (theme) => theme.spacing(5, 0),
                    backgroundColor: '#F2F1EE'
                }}
            >
                <Container>
                    <Typography variant="h3" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 2 }}>
                        COMPANY INTRODUCTION
                    </Typography>
                    <Typography variant="subtitle1">
                        We offer a variety of tour experiences to major destinations including Kingston, Montego Bay, Negril, Ocho Rios, and Portland. Licensed and certified tour operators and tour guides through The Tourist Product Development Company will make your experiences memorable and safe. We pride ourselves through differentiation from our competitors.
                    </Typography>
                </Container>
            </Container>

            {/* Visited Places Section */}
            <Container
                sx={{
                    padding: (theme) => theme.spacing(5, 0),
                    backgroundColor: '#FFF',
                }}
            >
                <Typography variant="h3" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 2 }}>
                    Places We Visit
                </Typography>
                <VisitedPlacesSlider />
            </Container>

            {/* Services Section */}
            <Container
                maxWidth={'xl'}
                sx={{
                    padding: (theme) => theme.spacing(5, 0),
                    backgroundColor: '#E69F08',
                    color: '#FFFFFF',
                }}
            >
                <Container>
                    <Typography variant="h3" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 2 }}>
                        SERVICES OFFERED
                    </Typography>
                </Container>
                <ServicesOffered />
            </Container>

            <Footer />
        </div>
    );
};

export default Home;
