import React, {useEffect, useState} from 'react';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { useCart } from '../Auth/CartContext';
import axios from "axios";
// import VisitedPlacesData from '../TestData/VisitedPlacesData';

const DestinationStep = () => {
    const { addToCart, selectedDestinations, removeFromCart } = useCart();

    const isAddedToCart = (id) => selectedDestinations.some((destination) => destination.destinationID === id);

    const handleCartAction = (id, price, url, name) => {
        if (isAddedToCart(id)) {
            removeFromCart(id);
        } else {
            addToCart(id, price, url, name);
        }
    };

    // State to hold the fetched destinations data
    const [DestinationsData, setDestinationsData] = useState([]);

    // Fetch the data when the component mounts
    useEffect(() => {

        axios.get('/api/get_destinations')

            .then(function (response) {
                // handle success, update state with fetched data
                console.log(response.data);
                setDestinationsData(response.data.destinations);
            })
            .catch(function (error) {
                // handle error
                console.error('Error fetching data:', error);
            });
    }, []);  // Empty array ensures this effect runs only once (on mount)


    return (
        <Grid container spacing={1} columns={16} sx={{ flexGrow: 1 }}>
            {DestinationsData?.map((data, index) => (
                <Grid item key={index} xs={8}>
                    <Card sx={{ display: 'flex', width: '100%', height: '300px', borderRadius: '8px', boxShadow: 8 }}>
                        <CardMedia component="img" sx={{ width: '50%', objectFit: 'cover', borderRadius: '8px 0 0 8px' }} image={data.dest_url} alt={data.dest_url} />
                        <CardContent sx={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ fontSize: '30px' }}>{data.name}</div>
                            <Typography variant="h6" sx={{ mt: 1, fontWeight: 'bold' }}>
                                $ {data.price}
                            </Typography>
                            <Typography variant="body2">{data.dest_description}</Typography>
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: isAddedToCart(data.id) ? '#ff0000' : '#2a3794', // Change colors based on the addedToCart condition
                                    marginTop: 'auto'
                                }}
                                size="large"
                                onClick={() => handleCartAction(data.id, data.price, data.dest_url, data.dest_description)}
                            >
                                {isAddedToCart(data.id) ? 'Remove Destination' : 'Add Destination'} &nbsp;&nbsp; {isAddedToCart(data.id) ? <ShoppingCartCheckoutIcon /> : <AddShoppingCartIcon />}
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default DestinationStep;
