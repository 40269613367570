import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from "@mui/material/Container";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import '@fontsource-variable/baloo-2';
import {createTheme, ThemeProvider} from "@mui/material";
import {useContext, useEffect} from "react";
import {UserContext} from "../Auth/UserProvider";
import {Link, Outlet, useNavigate} from 'react-router-dom';

const pages = [
    {name: 'Home', link: '/'},
    {name: 'Book Tour', link: '/booking'},
    {name: 'FAQs', link: '/faqs'},
    {name: 'Careers', link: '/careers'},
    // { name: 'Partnerships', link: '/partnerships' },
    {name: 'Contact', link: '/contact'},
];


const settings =
    [
        {name: 'My Account', link: "/account"},
        {name: 'Dashboard',link: "/dashboard"},
        {name: 'Logout', link: "#"}
    ];


const theme = createTheme({
    typography: {
        fontFamily: 'Baloo 2 Variable',
    },
});

function Navbar() {
    const navigate = useNavigate()
    const {user, logout} = useContext(UserContext);
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        logout(); // Assuming this clears the user context/state
        localStorage.removeItem("token"); // Clear the stored token if you're using one
        navigate('/login'); // Redirect to login page or any other public page
    };

    return (
        <ThemeProvider theme={theme}>
            <AppBar position="static" style={{backgroundColor: '#450272'}}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <img src="/images/logo/logosm.png" alt="Logo" style={{width: "200px"}}/>

                        <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {xs: 'block', md: 'none'},
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">{page.name}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                            <div style={{display: 'flex', alignItems: 'center', margin: 'auto'}}>
                                {pages.map((page, index) => (
                                    <Button
                                        key={page.name}
                                        component={Link}
                                        to={page.link}
                                        sx={{
                                            my: 2, color: 'white',
                                            display: 'block', textTransform: 'capitalize',
                                            fontSize: '16px', marginRight: index < pages.length - 1 ? 1 : 0,
                                            '&:hover': {
                                                backgroundColor: '#4A148C',
                                            }
                                        }}
                                    >
                                        {page.name}
                                    </Button>
                                ))}
                            </div>
                        </Box>

                        <Box sx={{flexGrow: 0}}>
                            {user?.auth ? (
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                        <Avatar alt={user?.firstName}  src="/static/images/avatar.jpg"/>
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Button
                                    key={"Login / Register"}
                                    onClick={() => navigate('login')}
                                    sx={{
                                        my: 2, color: 'white',
                                        display: 'block', textTransform: 'capitalize',
                                        '&:hover': {
                                            backgroundColor: '#4A148C',
                                        }
                                    }}
                                >
                                    Login / Register
                                </Button>
                            )}
                            {user?.auth && (
                                <Menu
                                    sx={{mt: '45px'}}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <MenuItem key={setting.name} onClick={() => {
                                            handleCloseUserMenu();
                                            if (setting.name === "Logout") {
                                                handleLogout();
                                            } else {
                                                navigate(setting.link); // For other settings that require navigation
                                            }
                                        }}>
                                            <Typography textAlign="center">{setting.name}</Typography>
                                        </MenuItem>
                                    ))}

                                </Menu>
                            )}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Outlet/>
        </ThemeProvider>
    );
}

export default Navbar;
