import React, { Fragment, useState } from 'react';
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from "@mui/material/Button";
import DestinationStep from "../Components/DestinationStep";
import BookingStep from '../Components/BookingStep';
import PaymentStep from '../Components/PaymentStep';
import ReviewStep from '../Components/ReviewStep';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useCart } from "../Auth/CartContext";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const Booking = () => {
    const steps = [
        'Choose Your Destinations',
        'Booking Information',
        'Payment Information',
        'Review & Complete Booking'
    ];

    const navigate = useNavigate();

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const { selectedDestinations, paymentInformation, bookingInformation } = useCart();

    const [activeStep, setActiveStep] = useState(0);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false); // Update the state to close the Snackbar
    };


    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [agree , setAgree] = useState(false);
    const handleNext = () => {
        switch (activeStep) {
            case 0:
                if (selectedDestinations.length === 0) {
                    setMessage("Please select at least one destination.");
                    setOpen(true);
                    return;
                }
                break;

            case 1:
                if (bookingInformation.numberOfAdults == 0 && bookingInformation.numberOfChildren > 0) {
                    handleIncompleteAdults(); // Open the dialog
                }

                if (
                    !bookingInformation.firstName ||
                    !bookingInformation.lastName ||
                    !bookingInformation.phone ||
                    !bookingInformation.email ||
                    !bookingInformation.numberOfChildren ||
                    !bookingInformation.numberOfAdults
                ) {
                    setMessage("Please complete all required fields.");
                    setOpen(true);
                    return;
                }
                break;

            case 2:
                if (
                    !paymentInformation.cardNumber ||
                    !paymentInformation.cardExpDate ||
                    !paymentInformation.cardCVC ||
                    !paymentInformation.cardHolderName
                ) {
                    setMessage("Please complete all required fields.");
                    setOpen(true);
                    return;
                }
                break;

            case 3:
                // Assuming your data is stored in a variable called 'requestData'
                const requestData = {
                    firstName: bookingInformation.firstName,
                    lastName: bookingInformation.lastName,
                    email: bookingInformation.email,
                    phone: bookingInformation.phone,
                    cardNumber: paymentInformation.cardNumber,
                    cardExpiration: paymentInformation.cardExpDate,
                    cardholderName: paymentInformation.cardHolderName,
                    numberOfAdults: bookingInformation.numberOfAdults,
                    numberOfChildren: bookingInformation.numberOfChildren,
                    subtotal: bookingInformation.subtotal,
                    tax: bookingInformation.tax,
                    total: bookingInformation.total,
                    date : bookingInformation.date,
                    time : bookingInformation.time,
                    destinations: selectedDestinations.map(destination => ({ id: destination.destinationID }))
                };

                // Make the POST request with headers
                axios.post('/api/payment_booking', requestData)
                    .then(response => {
                        // Handle success
                        console.log('Response:', response);

                        if(response.data.result.status === 203){
                            navigate("/success", { state: { "bookingNumber": response.data.result.message } });
                        }

                        else {
                            setActiveStep(2)
                            setOpen(true)
                            setMessage(response.data.result.message)
                        }
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error:', error);
                        alert(error)
                    });

                break;

            // Add similar checks for other steps

            default:
            // Do nothing for other steps
        }

        // Move to the next step if all validations pass
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const [openDialog, setOpenDialog] = useState(false);  // Add this line

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleIncompleteAdults = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleAgree = () => {
        // Continue with the next step or any other action
        setOpenDialog(false);
        // Move to the next step if all validations pass
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleDisagree = () => {
        // Handle disagreement (e.g., navigate back, show an error, etc.)
        setOpenDialog(false);
        return;
    };

    return (
        <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Incomplete Booking</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        At least one adult must be included in the booking. The company reserves the right to cancel
                        the booking if there are no accompanying adults.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDisagree} color="primary">
                        Disagree
                    </Button>
                    <Button onClick={handleAgree} color="primary" autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>

            <Navbar />
            <br /><br />
            <Container>
                <Typography variant={'h3'}>
                    Book Your Next Adventure
                </Typography>
            </Container>
            <br /><br />
            <Container>
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep} alternativeLabel sx={{ marginBottom: "70px" }}>
                        {steps.map((label, index) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>

                    <div style={{ marginBottom: "100px" }}>
                        {(() => {
                            switch (activeStep) {
                                case 0:
                                    return <DestinationStep />;
                                case 1:
                                    return <BookingStep />;
                                case 2:
                                    return <PaymentStep />;
                                case 3:
                                    return <ReviewStep />;
                                default:
                                    return null; // or a default component if needed
                            }
                        })()}
                    </div>

                    {activeStep === steps.length ? (
                        <Fragment>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button onClick={handleReset}>Redirecting</Button>
                            </Box>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="error"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1, width: "300px" }}
                                    variant="contained"
                                    startIcon={<ArrowBackIosIcon />}
                                >
                                    Back
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    sx={{ width: "300px", backgroundColor: '#450272' }}
                                    endIcon={<ArrowForwardIosIcon />}>
                                    {activeStep === steps.length - 1 ? 'Finalize Booking' : 'Continue to ' + steps[activeStep + 1]}
                                </Button>
                            </Box>
                        </Fragment>
                    )}
                </Box>
            </Container>
            <br />
            <Footer />
        </>
    );
};

export default Booking;
