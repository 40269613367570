import React from 'react';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Navbar from "../Components/Navbar";
import Container from "@mui/material/Container";
import Footer from "../Components/Footer";

const FAQComponent = () => {
    const faqData = [
        {
            question: 'What is your cancellation policy?',
            answer: 'Our cancellation policy allows you to cancel up to 48 hours before the scheduled activity without any charges.',
        },
        {
            question: 'How can I modify my booking?',
            answer: 'You can modify your booking by logging into your account and navigating to the "My Bookings" section. Alternatively, you can contact our customer support for assistance.',
        },
        {
            question: 'Are pets allowed on your tours?',
            answer: 'We love pets, but unfortunately, we do not allow them on our tours for the comfort and safety of all participants.',
        },
        {
            question: 'Is there a dress code for the tours?',
            answer: 'While there is no strict dress code, we recommend wearing comfortable clothing and appropriate footwear for the type of tour you are participating in.',
        },
        {
            question: 'What happens in case of bad weather?',
            answer: 'Tours will proceed as scheduled in light rain, but in the case of severe weather conditions, the tour may be canceled or rescheduled. Please check our weather policy for more details.',
        },
        {
            question: 'Do you offer group discounts?',
            answer: 'Yes, we offer group discounts for bookings with a specified minimum number of participants. Contact our group booking department for more information.',
        },
        {
            question: 'Can I bring my own food and drinks on the tour?',
            answer: 'Outside food and drinks are not allowed on most of our tours. However, some tours may have specific policies, so please check the tour details or contact us for clarification.',
        },
        {
            question: 'Can I get a refund if the tour is canceled due to weather conditions?',
            answer: 'Yes, if we cancel a tour due to adverse weather conditions, you will be eligible for a full refund. Please check our cancellation policy for more details.',
        },
    ];


    return (
        <>
            <Navbar/>
            <Container>
                <Typography variant="h3" align="center" color="primary" sx={{marginBottom:'80px', marginTop:'80px'}}>
                    Frequently Asked Questions
                </Typography>

                {faqData.map((item, index) => (
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`faq-content-${index}`} id={`faq-header-${index}`}>
                            <Typography variant="h6">{item.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{item.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Container>
            <br/>
            <Footer/>
        </>

    );
};

export default FAQComponent;
