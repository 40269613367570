import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCart } from "../Auth/CartContext";

const PaymentStep = () => {
    const { paymentInformation } = useCart();

    // Local state to hold the values for immediate updates
    const [localPaymentInfo, setLocalPaymentInfo] = useState(paymentInformation);

    // Update local state when context changes
    useEffect(() => {
        setLocalPaymentInfo(paymentInformation);
    }, [paymentInformation]);

    const handleChange = (field, value) => {
        // Update both local state and context
        setLocalPaymentInfo(prevState => ({
            ...prevState,
            [field]: value,
        }));
        paymentInformation[field] = value;
    };

    return (
        <Stack spacing={2}>
            <Typography variant="h5" align="center" color="primary">
                Payment Information
            </Typography>

            {/* Card Number */}
            <TextField
                label="Card Number"
                variant="outlined"
                fullWidth
                required
                name="cardNumber"
                onChange={(e) => handleChange('cardNumber', e.target.value)}
                inputProps={{ style: { borderRadius: 8 } }}
                value={localPaymentInfo.cardNumber || ''}
            />

            {/* Expiration Date */}
            <TextField
                label="Expiration Date"
                variant="outlined"
                fullWidth
                required
                name="expirationDate"
                onChange={(e) => handleChange('cardExpDate', e.target.value)}
                inputProps={{ style: { borderRadius: 8 } }}
                value={localPaymentInfo.cardExpDate || ''}
            />

            {/* Card Code (CVC) */}
            <TextField
                label="Card Code (CVC)"
                variant="outlined"
                fullWidth
                required
                name="cardCode"
                onChange={(e) => handleChange('cardCVC', e.target.value)}
                inputProps={{ style: { borderRadius: 8 } }}
                value={localPaymentInfo.cardCVC || ''}
            />

            {/* Cardholder's Name */}
            <TextField
                label="Cardholder's Name"
                variant="outlined"
                fullWidth
                required
                name="cardholderName"
                onChange={(e) => handleChange('cardHolderName', e.target.value)}
                inputProps={{ style: { borderRadius: 8 } }}
                value={localPaymentInfo.cardHolderName || ''}
            />
        </Stack>
    );
};

export default PaymentStep;
