import React from 'react';
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {useLocation} from "react-router-dom";

const Success = () => {

    const location = useLocation();
    return (
        <>
            <Navbar/>
            <Container sx={{ marginTop: '80px', marginBottom: '80px' }}>
                <Typography variant="h3" align="center" color="primary">
                    Your Booking Was Successful!
                </Typography>

                <Typography variant="h5" align="center" color="primary">
                    Thank you for booking with Catch a Vibes Bolt Tours and Getaways.
                </Typography>

                <br/>

                <Typography variant="h5" align="center" color="primary">
                    Your Booking number : <br/>
                </Typography>

                <br/>

                <Typography variant="h2" align="center" color="warning">
                    {location.state.bookingNumber}
                </Typography>

                <Typography variant="h5" align="center" color="primary">
                    An email has been sent with your booking confirmation details.
                </Typography>
            </Container>
            <Footer/>
        </>
    );
};

export default Success;
