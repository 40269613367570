import React, {useContext, useEffect, useState} from 'react';
import {
    Container,
    Typography,
    Grid,
    Paper,
    TextField,
    Button,
    Divider,
    ThemeProvider,
    createTheme,
    Skeleton, Alert
} from '@mui/material';
import Navbar from '../Components/Navbar'; // Adjust the import path as necessary
import Footer from '../Components/Footer';
import {UserContext} from "../Auth/UserProvider";
import axios from "axios"; // Adjust the import path as necessary

const theme = createTheme();

const AccountSettings = () => {
    const [userInfo, setUserInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        points: 120,
    });

    const handleChange = (event) => {
        setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
    };

    const [alert, setAlert] = useState({ show: false, severity: null , message: '' });

    const handleSave = () => {
        let token = localStorage.getItem("token")


        const isFormValid = Object.values(userInfo).every(value => value !== null && value !== "");

        if (!isFormValid) {
            setAlert({ show: true, severity: 'error', message: 'Please complete all fields.' });
            return;
        }

        axios.put( "http://68.183.63.127:8080/api/v1/updateUser",userInfo,{headers: {
                Authorization: `Bearer ${token}`,
            }})
            .then((response) =>
            {

                if (response.status === 200) {
                    setAlert({ show: true, severity: 'success', message: 'Information successfully updated.' });
                } else {
                    setAlert({ show: true, severity: 'error', message: 'Cannot update user.' });
                }
            })
    };

    const {user, verifyUser} = useContext(UserContext)
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        let shouldVerifyUser = false;

        for (const value of Object.values(userInfo)) {
            if (value === "" || value === null) {
                shouldVerifyUser = true;
                break;
            }
        }

        if (shouldVerifyUser) {
            verifyUser();
        }


        const loadData = async () => {
            if (user) {
                setUserInfo({
                    firstName: user.firstName || "",
                    lastName: user.lastName || "",
                    email: user.email || "",
                    phone: user.phone || "",
                    points: 120,
                });
                setIsLoading(false);
            }
        };

        loadData();
    }, [user]);

    return (
        <ThemeProvider theme={theme}>
            <Navbar/>
            <Container sx={{py: 4, backgroundColor: '#f0f2f5', minHeight: '100vh'}}>
                <Typography variant="h4" sx={{my: 5, textAlign: 'center', color: '#333', fontWeight: 'bold'}}>
                    Account Information
                </Typography>
                <Divider sx={{mb: 4}}/>
                {alert.show && <Alert severity={alert.severity as "error" | "info" | "success" | "warning"} sx={{ mb: 2 }}>{alert.message}</Alert>}

                {isLoading ? [...Array(5)].map((e, i) => (
                        <Skeleton key={i} variant="rectangular" width="100%" height={56} sx={{borderRadius: '4px', mb: 2}}/>
                    )) :
                    <Grid container spacing={4} justifyContent="center">
                        <Grid item xs={12} md={6}>
                            <Paper sx={{
                                p: 3,
                                borderRadius: '15px',
                                boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                                backgroundColor: '#ffffff',
                                color: '#333',
                            }}>
                                <TextField
                                    fullWidth
                                    label="First Name"
                                    name="firstName"
                                    value={userInfo.firstName}
                                    onChange={handleChange}
                                    margin="normal"
                                    required
                                />
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    name="lastName"
                                    value={userInfo.lastName}
                                    onChange={handleChange}
                                    margin="normal"
                                    required
                                />
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    value={userInfo.email}
                                    onChange={handleChange}
                                    margin="normal"
                                    disabled
                                />
                                <TextField
                                    fullWidth
                                    label="Phone"
                                    name="phone"
                                    value={userInfo.phone}
                                    onChange={handleChange}
                                    margin="normal"
                                    required
                                />
                                <Typography sx={{mt: 2}}>Points: {userInfo.points}</Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{mt: 3}}
                                    onClick={handleSave}
                                >
                                    Save Changes
                                </Button>
                            </Paper>
                        </Grid>
                    </Grid>
                }
            </Container>
            <Footer/>
        </ThemeProvider>
    );
};

export default AccountSettings;
