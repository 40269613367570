// RegisterForm.js
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Navbar from "../Components/Navbar";
import {Container, Grid, Select, MenuItem, InputLabel, FormControl, Alert} from '@mui/material';
import Footer from "../Components/Footer";
import axios from "axios";
import {useNavigate} from "react-router-dom";


const Register = () => {
    const navigate = useNavigate();

    const [error, setError] = useState('');

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Check if passwords match
        if (formData.password !== formData.confirmPassword) {
            setError('Passwords do not match.');
            return; // Stop the submission if passwords do not match
        }

        // Make the POST request
        axios.post( 'http://bolt2024.catchavibes.com/api/v1/public/register', formData)
            .then(response => {
                // Handle success
                console.log('Response:', response.data)

                navigate("/login")
            })
            .catch(error => {
                // Handle error
                setError("Error registering user. Please contact customer service.")
            });

        // console.log('Registration form submitted:', formData);
    };

    const states = [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
        'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts',
        'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico',
        'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
        'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
    ];



    return (
        <>
            <Navbar/>

            <Container sx={{ mt: 10, mb: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h3" color="primary" gutterBottom>
                    Create Account
                </Typography>
                <br />
                <form onSubmit={handleSubmit}>
                    <Typography variant="h6" color="primary" gutterBottom>
                        Personal Information
                    </Typography>
                    <TextField
                        label="First Name"
                        variant="outlined"
                        fullWidth
                        required
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <TextField
                        label="Last Name"
                        variant="outlined"
                        fullWidth
                        required
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        required
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <TextField
                        label="Phone"
                        variant="outlined"
                        fullWidth
                        required
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        margin="normal"
                    />

                    <Typography variant="h6" color="primary" gutterBottom style={{ marginTop: '20px' }}>
                        Create Password
                    </Typography>
                    <TextField
                        label="Password"
                        variant="outlined"
                        fullWidth
                        required
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <TextField
                        label="Confirm Password"
                        variant="outlined"
                        fullWidth
                        required
                        type="password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        margin="normal"
                    />

                    {/*<Typography variant="h6" color="primary" gutterBottom style={{ marginTop: '20px' }}>*/}
                    {/*    Address Information*/}
                    {/*</Typography>*/}
                    {/*<Grid container spacing={2}>*/}
                    {/*    <Grid item xs={12} md={6}>*/}
                    {/*        <TextField*/}
                    {/*            label="Street"*/}
                    {/*            variant="outlined"*/}
                    {/*            fullWidth*/}
                    {/*            required*/}
                    {/*            name="street"*/}
                    {/*            value={formData.street}*/}
                    {/*            onChange={handleChange}*/}
                    {/*            margin="normal"*/}
                    {/*        />*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={12} md={6}>*/}
                    {/*        <TextField*/}
                    {/*            label="City"*/}
                    {/*            variant="outlined"*/}
                    {/*            fullWidth*/}
                    {/*            required*/}
                    {/*            name="city"*/}
                    {/*            value={formData.city}*/}
                    {/*            onChange={handleChange}*/}
                    {/*            margin="normal"*/}
                    {/*        />*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={12} md={6}>*/}
                    {/*        <FormControl fullWidth variant="outlined" required margin="normal">*/}
                    {/*            <InputLabel style={{ marginBottom: '8px' }}>State</InputLabel>*/}
                    {/*            <Select*/}
                    {/*                label="State"*/}
                    {/*                name="state"*/}
                    {/*                value={formData.state}*/}
                    {/*                onChange={handleChange}*/}
                    {/*            >*/}
                    {/*                {states.map((state) => (*/}
                    {/*                    <MenuItem key={state} value={state}>*/}
                    {/*                        {state}*/}
                    {/*                    </MenuItem>*/}
                    {/*                ))}*/}
                    {/*            </Select>*/}
                    {/*        </FormControl>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={12} md={6}>*/}
                    {/*        <TextField*/}
                    {/*            label="ZIP Code"*/}
                    {/*            variant="outlined"*/}
                    {/*            fullWidth*/}
                    {/*            required*/}
                    {/*            name="zip"*/}
                    {/*            value={formData.zip}*/}
                    {/*            onChange={handleChange}*/}
                    {/*            margin="normal"*/}
                    {/*        />*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    <br/>
                    {error && <Alert severity="error" sx={{ width: '50%', mb: 2 }}>{error}</Alert>}
                    <br/>

                    <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: '20px' }}>
                        Create Account
                    </Button>
                </form>

            </Container>
            <Footer/>
        </>
    );
};

export default Register;
