import './App.css';
import Home from "./Pages/Home";
import '@fontsource-variable/baloo-2';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Booking from "./Pages/Booking";
import { CartProvider } from "./Auth/CartContext";
import FAQs from "./Pages/FAQs";
import Contact from "./Pages/Contact";
import NoPage from "./Pages/NoPage";
import Register from "./Pages/Register";
import Login from "./Pages/Login";
import Dashboards from "./Pages/Dashboards";
import { UserContext } from "./Auth/UserProvider";
import Success from "./Pages/Success";
import AccountSettings from "./Pages/AccountSettings";

const theme = createTheme({
    palette: {
        primary: {
            main: '#8A2BE2', // Bold purple
        },
        secondary: {
            main: '#E69F08', // Teal
        },
    },
    typography: {
        fontFamily: 'Baloo 2 Variable',
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#8A2BE2', // Bold purple
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: '10px',
                },
            },
        },
    },
    spacing: 4, // Default spacing for paddings and margins
});

function App() {
    const { user, verifyUser } = useContext(UserContext);

    useEffect(() => {
        verifyUser();
        // TODO: Redirect to login if not verified
    }, []);

    return (
        <CartProvider>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Routes>
                        <Route index element={<Home />} />
                        <Route path="booking" element={<Booking />} />
                        <Route path="faq" element={<FAQs />} />
                        <Route path="contact" element={<Contact />} />
                        <Route path="login" element={user?.auth ? <Dashboards /> : <Login />} />
                        <Route path="register" element={user?.auth ? <Dashboards /> : <Register />} />
                        <Route path="success" element={<Success />} />
                        <Route path="dashboard" element={user?.auth ? <Dashboards /> : <Login />} />
                        <Route path="account" element={user?.auth ? <AccountSettings /> : <Login />} />
                        <Route path="*" element={<NoPage />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </CartProvider>
    );
}

export default App;
