// LoginForm.js
// noinspection TypeScriptValidateTypes
// @ts-nocheck
import React, {useContext, useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from "@mui/material/Container";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import {Link, useNavigate} from "react-router-dom";
import axios, {AxiosError, AxiosRequestConfig} from "axios";
import {UserContext} from "../Auth/UserProvider";
import {Alert} from "@mui/material";

const LoginForm = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [error, setError] = useState('');

    const userCtx = useContext(UserContext);
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({...prevData, [name]: value}));
    };

    let navigate = useNavigate();


    const handleSubmit = (e) => {
        e.preventDefault();
        if(formData.email == "" || formData.password == "")
        {
            return(
                <Alert severity="error">Please complete both fields. </Alert>
            )
        }
        axios.post('http://bolt2024.catchavibes.com/api/v1/public/login', formData,
            {
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Methods': '*',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            })
            .then(response => {
                console.log(response)
                if (response.data == null && response.status !== 200) {
                    // alert("Login information Incorrect.")
                    return(
                        <Alert severity="error">Login information incorrect. </Alert>
                    )
                } else {
                    localStorage.setItem("token", response.data);
                    userCtx.login(
                        response.data.firstName,
                        response.data.lastName,
                        response.data.email,
                        response.data.phone,
                        true,
                        response.data.token);
                    navigate("/dashboard")
                }
            })
            .catch(error => {
                //TODO create logger to log errors with login
                setError(error.message)

            });
    }
    return (
        <>
            <Navbar/>
            <Container sx={{ mt: 10, mb: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h4" color="primary" gutterBottom sx={{ mb: 4 }}>
                    Login
                </Typography>

                <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: 500 }}>
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        required
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        fullWidth
                        required
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <Button onClick={handleSubmit} variant="contained" color="primary" fullWidth sx={{ mt: 3, mb: 2 }}>
                        Login
                    </Button>
                </form>
                <Typography mt={2}>
                    Not yet registered?
                    <Link to='/register'  style={{ marginLeft: 1, fontWeight: 'bold', textDecoration:"none"}}>
                        Click here to sign up
                    </Link>
                </Typography>
                {error && <Alert severity="error" sx={{ width: '50%', mb: 2 }}>{error}</Alert>}
            </Container>
            <Footer/>
        </>


    );
};

export default LoginForm;
